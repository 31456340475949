import { getUpgradeSetting, saveUpgradeSetting } from '@/api/basic';

import { Button, Checkbox, CheckboxGroup, TimePicker, Cascader } from 'element-ui';
import basicHeader from '@/components/backStageHeader';

export default {
  name: 'Upgrade',
  props: {},
  data() {
    return {
      checkAll: false,
      periodList: [],
      gradeTypeList: [],
      time: '',
      checkList: [],
      date: [],
      options: [],
      initState: '',
    };
  },
  components: {
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [TimePicker.name]: TimePicker,
    [Cascader.name]: Cascader,
    basicHeader,
  },
  created() {
    this.init();
    this.initOptions();
  },
  mounted() {},
  methods: {
    initOptions() {
      let res = [];
      for (let i = 1; i <= 12; i++) {
        let month = [];

        const len = getDays(2021, i, 0);
        for (let j = 1; j <= len; j++) {
          month.push({
            value: `${i}-${j}`,
            label: (j > 9 ? j : `0${j}`) + '日',
          });
        }

        res.push({
          value: `${i}`,
          label: (i > 9 ? i : `0${i}`) + '月',
          children: month,
        });
      }
      this.options = res;

      function getDays(year, month) {
        return new Date(year, month, 0).getDate();
      }
    },
    init() {
      getUpgradeSetting().then((res) => {
        if (res && res.status === 0) {
          const {
            setting: { periodList, showTipTime },
          } = res.result;

          for (let i = 0; i < periodList.length; i++) {
            let res = [];
            const item = periodList[i];
            for (let j = 0; j < item.gradeTypeList.length; j++) {
              if (item.gradeTypeList[j].withStudent === 1) {
                res.push(item.gradeTypeList[j].gradeTypeId);
              }
            }
            item.checkList = res;
          }
          const { day, hour, minute, month } = showTipTime;
          const time = `${hour}:${minute}`;
          const date = [`${month}`, `${month}-${day}`];

          this.initState = JSON.stringify({
            time,
            date,
            periodList,
          });
          this.date = date;
          this.time = time;
          this.periodList = periodList;
        }
      });
    },

    handleSave() {
      let res = [];
      for (let i = 0; i < this.periodList.length; i++) {
        const item = this.periodList[i],
          checkList = item.checkList;
        for (let j = 0; j < item.gradeTypeList.length; j++) {
          if (checkList.indexOf(item.gradeTypeList[j].gradeTypeId) > -1) {
            item.gradeTypeList[j].withStudent = 1;
          } else {
            item.gradeTypeList[j].withStudent = 0;
          }
          res.push(item.gradeTypeList[j]);
        }
      }

      const data = JSON.stringify({
        gradeData: res,
        upgradeTime: {
          month: this.date[0],
          day: this.date[1].split('-')[1],
          hour: this.time.split(':')[0],
          minute: this.time.split(':')[1],
        },
      });
      saveUpgradeSetting({
        data,
      }).then((res) => {
        if (res && res.result === 0) {
          this.initState = JSON.stringify({
            time: this.time,
            date: this.date,
            periodList: this.periodList,
          });
          this.$message.success('保存成功');
        }
      });
    },

    handleCancle() {
      const { time, date, periodList } = JSON.parse(this.initState);
      console.log(time, date, periodList);
      this.time = time;
      this.date = date;
      this.periodList = periodList;
    },
  },
};
